$font-small: 0.875rem;
$font-medium: 1rem;

.sidebar-nav {
  font-size: $font-medium;
}

table {
  thead tr th {
    font-size: $font-small;
  }

  tbody tr {
    th {
      font-size: $font-small;
    }

    td {
      font-size: $font-small;
    }
  }
}

.list-group-item {
  font-size: $font-small;
}

.form-label,
label {
  font-size: $font-small;
  font-weight: 600;
}

.nav-tabs .nav-item .nav-link {
  font-size: $font-small;
}

.form-error {
  font-size: $font-small;
}

select {
  font-size: $font-small !important;
}

.action-btn {
  padding: 3px 7px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: .375rem .75rem;
  border-radius: 0.375rem;
  border: 1px solid #d8dbe0;
  font-size: 12px;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #6610f2;
  box-shadow: 0 0 5px #6610f2;
}